import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

exports.stripeWebhook = functions.https.onRequest(async (req, res) => {
  let event;
  try {
    // For signature verification, get your webhook signing secret from Stripe Dashboard
    const webhookSecret = functions.config().stripe.webhook_secret;
    const signature = req.headers["stripe-signature"];
    event = stripe.webhooks.constructEvent(
      req.rawBody,
      signature,
      webhookSecret
    );
  } catch (err) {
    console.error("Webhook signature verification failed.", err.message);
    return res.sendStatus(400);
  }

  // Handle event
  switch (event.type) {
    case "checkout.session.completed":
      const session = event.data.object;
      // Fulfill the purchase, e.g. update DB, send emails, etc.
      break;
    // ... handle other event types
    default:
      console.log(`Unhandled event type ${event.type}`);
  }

  res.sendStatus(200);
});