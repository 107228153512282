import React, { useState, useEffect, useRef } from "react";
import { Trash2 } from "lucide-react";
import flowFrameLogo from "./data/FlowFrame Logo.png";

// Utility for generating random IDs
function generateId() {
  return Math.random().toString(36).substr(2, 9);
}

/**
 * PracticeItem:
 * {
 *   id: string,
 *   title: string,
 *   projectedMinutes: number,
 *   parentId: string | null,
 *   actualElapsedMs: number,
 *   isRunning: boolean,
 *   startTime: number | null,
 *   splits: Array<{ time: number, label: string }>,
 *   comments: string,
 *
 *   // NEW FIELD:
 *   clickTracks: Array<{
 *     name: string;
 *     folder: string;
 *   }>,
 * }
 *
 * A "session" in sessionHistory:
 * {
 *   id: string,
 *   date: number,
 *   items: PracticeItem[], // with children nested
 *   reflection: {
 *     q1: string,
 *     q2: string,
 *     q3: string,
 *   }
 * }
 *
 * A "plan" in practicePlans:
 * {
 *   id: string,
 *   label: string,         // user-given name, e.g. "Daily Warmups"
 *   items: PracticeItem[], // stored *flat* or *tree*
 * }
 */

export default function PracticeTrackerTab() {
  const [practiceItems, setPracticeItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [mode, setMode] = useState("planning"); // "planning", "active", "review"
  const [sessionHistory, setSessionHistory] = useState([]);

  // Collection of user-labeled plans
  const [practicePlans, setPracticePlans] = useState([]);
  const [currentPlanId, setCurrentPlanId] = useState(null); // which plan is active
  const [planLabelInput, setPlanLabelInput] = useState("");

  // Reflection states
  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [tempReflection, setTempReflection] = useState({
    q1: "",
    q2: "",
    q3: "",
  });

  const updateIntervalRef = useRef(null);

  // EXAMPLE: We assume you have the user’s click tracks in savedPatterns.
  // This array is similar to your main app’s “savedPatterns,” each with name & folder.
  const [savedPatterns, setSavedPatterns] = useState(() => {
    // Demo data or read from localStorage. For example:
    // try {
    //   const sp = JSON.parse(localStorage.getItem("metronomePatterns")) || [];
    //   return sp;
    // } catch {
    //   return [];
    // }
    return [
      { name: "Slow Warmup", folder: "Warmups" },
      { name: "Fast Warmup", folder: "Warmups" },
      { name: "Odd Meters", folder: "Drills" },
      { name: "Polyrhythm 3 vs 2", folder: "Drills" },
      { name: "All 12 Keys Medium Swing", folder: "Jazz" },
    ];
  });

  // For the mini-UI: store which folder is selected, and maintain a set of chosen patterns
  const [selectedFolderForClickTracks, setSelectedFolderForClickTracks] =
    useState("");
  // If you want a multi-select, we might store an array or set of pattern names.
  // But we actually store them inside each item’s `clickTracks`.
  // So we only keep the “currently toggled” state in a local UI.

  // ─────────────────────────────────────────────────────────────────────
  //   LOADING & SAVING (LOCALSTORAGE)
  // ─────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const savedItems = localStorage.getItem("practiceItems_v2");
    const savedSessions = localStorage.getItem("sessionHistory_v2");
    const savedPlans = localStorage.getItem("practicePlans_v2");
    const savedCurrentPlan = localStorage.getItem("currentPlanId_v2");

    if (savedItems) {
      try {
        setPracticeItems(JSON.parse(savedItems));
      } catch (err) {
        console.error("Error parsing practiceItems:", err);
      }
    }
    if (savedSessions) {
      try {
        setSessionHistory(JSON.parse(savedSessions));
      } catch (err) {
        console.error("Error parsing sessionHistory:", err);
      }
    }
    if (savedPlans) {
      try {
        setPracticePlans(JSON.parse(savedPlans));
      } catch (err) {
        console.error("Error parsing practicePlans:", err);
      }
    }
    if (savedCurrentPlan) {
      setCurrentPlanId(savedCurrentPlan);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("practiceItems_v2", JSON.stringify(practiceItems));
  }, [practiceItems]);

  useEffect(() => {
    localStorage.setItem("sessionHistory_v2", JSON.stringify(sessionHistory));
  }, [sessionHistory]);

  useEffect(() => {
    localStorage.setItem("practicePlans_v2", JSON.stringify(practicePlans));
  }, [practicePlans]);

  useEffect(() => {
    if (currentPlanId) {
      localStorage.setItem("currentPlanId_v2", currentPlanId);
    }
  }, [currentPlanId]);

  // ─────────────────────────────────────────────────────────────────────
  //   BUILD TREE
  // ─────────────────────────────────────────────────────────────────────
  function buildTree(items, parentId = null) {
    return items
      .filter((it) => it.parentId === parentId)
      .map((it) => ({
        ...it,
        children: buildTree(items, it.id),
      }));
  }
  const treeData = buildTree(practiceItems);

  // ─────────────────────────────────────────────────────────────────────
  //   HELPER: ROLL-UP SUB-ITEM TIMES
  // ─────────────────────────────────────────────────────────────────────
  function computeRolledUpTime(item) {
    let total = item.actualElapsedMs || 0;
    if (item.children?.length) {
      for (const child of item.children) {
        total += computeRolledUpTime(child);
      }
    }
    return total;
  }

  // ─────────────────────────────────────────────────────────────────────
  //   PROJECTED TIME AUTO-UPDATE
  // ─────────────────────────────────────────────────────────────────────
  function updateParentProjections(allItems, changedItemId) {
    // Build a map from parentId -> array of children
    const childrenMap = {};
    for (const item of allItems) {
      const pid = item.parentId;
      if (!pid) continue;
      if (!childrenMap[pid]) {
        childrenMap[pid] = [];
      }
      childrenMap[pid].push(item);
    }

    // Find all ancestors up the chain
    function getAncestors(itemId, arr = []) {
      const item = allItems.find((it) => it.id === itemId);
      if (!item || !item.parentId) return arr;
      arr.push(item.parentId);
      return getAncestors(item.parentId, arr);
    }
    const ancestors = getAncestors(changedItemId);

    // For each ancestor, enforce parent's projectedMinutes >= sum of children
    return allItems.map((it) => {
      if (!ancestors.includes(it.id)) {
        return it;
      }
      const kids = childrenMap[it.id] || [];
      const sumKids = kids.reduce((acc, kid) => acc + kid.projectedMinutes, 0);
      if (sumKids > it.projectedMinutes) {
        return { ...it, projectedMinutes: sumKids };
      }
      return it;
    });
  }

  // ─────────────────────────────────────────────────────────────────────
  //   CRUD
  // ─────────────────────────────────────────────────────────────────────
  function handleAddItem(parentId = null) {
    const newItem = {
      id: generateId(),
      title: parentId ? "New Sub-Item" : "New Practice Block",
      projectedMinutes: 5,
      parentId,
      actualElapsedMs: 0,
      isRunning: false,
      startTime: null,
      splits: [],
      comments: "",

      // NEW: each item has its own array of clickTracks
      clickTracks: [],
    };
    setPracticeItems((prev) => [...prev, newItem]);
    setExpandedItems((prev) => ({ ...prev, [newItem.id]: true }));
  }

  function handleDeleteItem(id) {
    // remove item + all descendants
    function removeRecursive(items, targetId) {
      const withoutTarget = items.filter((x) => x.id !== targetId);
      // Then remove direct children
      const filtered = withoutTarget.filter((x) => x.parentId !== targetId);
      if (filtered.length === items.length) return filtered;
      return removeRecursive(filtered, targetId);
    }
    setPracticeItems((prev) => removeRecursive(prev, id));
  }

  function handleChangeTitle(id, newTitle) {
    setPracticeItems((prev) =>
      prev.map((it) => (it.id === id ? { ...it, title: newTitle } : it))
    );
  }

  function handleChangeProjection(id, newVal) {
    const numericVal = Number(newVal) || 0;
    setPracticeItems((prev) => {
      let updated = prev.map((it) =>
        it.id === id ? { ...it, projectedMinutes: numericVal } : it
      );
      updated = updateParentProjections(updated, id);
      return updated;
    });
  }

  function handleChangeComments(id, newComments) {
    setPracticeItems((prev) =>
      prev.map((it) => (it.id === id ? { ...it, comments: newComments } : it))
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   CLICK TRACK SELECTION LOGIC
  // ─────────────────────────────────────────────────────────────────────

  // Let each item expand/collapse a small UI for choosing click tracks.
  // We'll store a separate boolean “isSelectingTracks[itemId]”
  const [isSelectingTracks, setIsSelectingTracks] = useState({});

  function toggleSelectingTracks(itemId) {
    setIsSelectingTracks((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  }

  // For multi-select, we either store them as we go or handle them with checkboxes.
  // We'll show a folder dropdown, then list patterns in that folder with checkboxes.

  function handleToggleClickTrack(itemId, pattern) {
    // pattern is { name, folder }
    // If it’s already in item.clickTracks, remove it; else add it.
    setPracticeItems((prev) =>
      prev.map((it) => {
        if (it.id !== itemId) return it;

        const already = it.clickTracks.some(
          (ct) => ct.name === pattern.name && ct.folder === pattern.folder
        );
        let newList;
        if (already) {
          newList = it.clickTracks.filter(
            (ct) => !(ct.name === pattern.name && ct.folder === pattern.folder)
          );
        } else {
          newList = [...it.clickTracks, pattern];
        }
        return { ...it, clickTracks: newList };
      })
    );
  }

  function isClickTrackSelected(itemId, pattern) {
    const item = practiceItems.find((it) => it.id === itemId);
    if (!item) return false;
    return item.clickTracks.some(
      (ct) => ct.name === pattern.name && ct.folder === pattern.folder
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   PLAN-SPECIFIC LOGIC
  // ─────────────────────────────────────────────────────────────────────

  function handleSavePlan() {
    if (!planLabelInput.trim()) {
      alert("Please enter a label for this plan.");
      return;
    }
    const planId = currentPlanId || generateId();
    const newPlan = {
      id: planId,
      label: planLabelInput,
      items: practiceItems, // store the entire array
    };

    setPracticePlans((prev) => {
      const existing = prev.findIndex((p) => p.id === planId);
      if (existing >= 0) {
        const copy = [...prev];
        copy[existing] = newPlan;
        return copy;
      } else {
        return [...prev, newPlan];
      }
    });
    setCurrentPlanId(planId);

    alert(`Plan "${planLabelInput}" saved!`);
  }

  function handleSelectPlan(e) {
    const planId = e.target.value;
    setCurrentPlanId(planId);
    if (!planId) return;
    const plan = practicePlans.find((p) => p.id === planId);
    if (!plan) return;
    setPlanLabelInput(plan.label);
    setPracticeItems(plan.items);
  }

  function handleDeletePlan() {
    if (!currentPlanId) return;
    const confirmDel = window.confirm("Delete this plan permanently?");
    if (!confirmDel) return;
    setPracticePlans((prev) => prev.filter((p) => p.id !== currentPlanId));
    setCurrentPlanId(null);
    setPlanLabelInput("");
  }

  // ─────────────────────────────────────────────────────────────────────
  //   IMPORT / EXPORT (FILE)
  // ─────────────────────────────────────────────────────────────────────

  function handleExportSessions() {
    const dataStr = JSON.stringify(sessionHistory, null, 2);
    downloadFile(dataStr, "flowframe_sessions.txt");
  }

  function handleExportPlans() {
    const dataStr = JSON.stringify(practicePlans, null, 2);
    downloadFile(dataStr, "flowframe_plans.txt");
  }

  function handleExportSinglePlan() {
    if (!currentPlanId) {
      alert("No plan selected to export.");
      return;
    }
    const plan = practicePlans.find((p) => p.id === currentPlanId);
    if (!plan) {
      alert("Could not find that plan.");
      return;
    }
    const dataStr = JSON.stringify(plan, null, 2);
    const safeLabel = plan.label.replace(/\s+/g, "_");
    downloadFile(dataStr, `flowframe_plan_${safeLabel}.txt`);
  }

  function downloadFile(text, filename) {
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  function handleImportSessions(event) {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const imported = JSON.parse(e.target.result);
        setSessionHistory(imported);
        alert("Sessions imported successfully!");
      } catch (err) {
        alert("Error parsing sessions file: " + err);
      }
    };
    reader.readAsText(file);
  }

  function handleImportPlans(event) {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const imported = JSON.parse(e.target.result);
        setPracticePlans(imported);
        alert("Plans imported successfully!");
      } catch (err) {
        alert("Error parsing plans file: " + err);
      }
    };
    reader.readAsText(file);
  }

  // ─────────────────────────────────────────────────────────────────────
  //   TIMER LOGIC
  // ─────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (mode === "active") {
      startGlobalUpdateLoop();
    } else {
      stopGlobalUpdateLoop();
    }
    return () => stopGlobalUpdateLoop();
  }, [mode]);

  function startGlobalUpdateLoop() {
    if (updateIntervalRef.current) return;
    updateIntervalRef.current = setInterval(() => {
      setPracticeItems((prev) =>
        prev.map((item) => {
          if (!item.isRunning || item.startTime == null) return item;
          const now = Date.now();
          const diff = now - item.startTime;
          return {
            ...item,
            actualElapsedMs: item.actualElapsedMs + diff,
            startTime: now,
          };
        })
      );
    }, 1000);
  }

  function stopGlobalUpdateLoop() {
    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
      updateIntervalRef.current = null;
    }
  }

  function stopAllTimers() {
    setPracticeItems((prev) =>
      prev.map((it) => {
        if (it.isRunning && it.startTime != null) {
          const now = Date.now();
          const diff = now - it.startTime;
          return {
            ...it,
            isRunning: false,
            actualElapsedMs: it.actualElapsedMs + diff,
            startTime: null,
          };
        }
        return it;
      })
    );
  }

  function toggleExpand(id) {
    setExpandedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  }

  function handleItemClick(item) {
    if (mode !== "active") return;
    stopAllTimers();
    setPracticeItems((prev) =>
      prev.map((it) =>
        it.id === item.id
          ? { ...it, isRunning: true, startTime: Date.now() }
          : it
      )
    );
    setSelectedItemId(item.id);
  }

  function handleSplit(id) {
    setPracticeItems((prev) =>
      prev.map((it) => {
        if (it.id !== id) return it;
        if (!it.isRunning || it.startTime == null) return it;
        const now = Date.now();
        const diff = now - it.startTime;
        const totalSoFar = it.actualElapsedMs + diff;
        return {
          ...it,
          splits: [...it.splits, { time: totalSoFar, label: "" }],
        };
      })
    );
  }

  function startItemTimer(id) {
    stopAllTimers();
    setPracticeItems((prev) =>
      prev.map((it) =>
        it.id === id ? { ...it, isRunning: true, startTime: Date.now() } : it
      )
    );
  }

  function stopItemTimer(id) {
    setPracticeItems((prev) =>
      prev.map((it) => {
        if (it.id !== id) return it;
        if (it.isRunning && it.startTime != null) {
          const now = Date.now();
          const diff = now - it.startTime;
          return {
            ...it,
            isRunning: false,
            actualElapsedMs: it.actualElapsedMs + diff,
            startTime: null,
          };
        }
        return it;
      })
    );
  }

  function formatTime(ms) {
    const totalSec = Math.floor(ms / 1000);
    const m = Math.floor(totalSec / 60);
    const s = totalSec % 60;
    return `${m}:${s < 10 ? "0" + s : s}`;
  }

  function getTimeColor(item) {
    const actualMin = Math.floor(item.actualElapsedMs / 1000 / 60);
    const proj = item.projectedMinutes || 0;
    if (proj <= 0) return "text-gray-600";
    if (actualMin < proj) return "text-green-600";
    if (actualMin > proj) return "text-red-600";
    return "text-gray-600";
  }

  // ─────────────────────────────────────────────────────────────────────
  //   SESSIONS & ENDING
  // ─────────────────────────────────────────────────────────────────────

  function handleStartSession() {
    setMode("active");
  }

  function handleStopSessionConfirmation() {
    const confirmEnd = window.confirm(
      "Are you sure you want to end this session?"
    );
    if (!confirmEnd) return;
    setTempReflection({ q1: "", q2: "", q3: "" });
    setShowReflectionModal(true);
  }

  function finalizeSession(reflectionAnswers = null) {
    setShowReflectionModal(false);
    const itemsWithTree = buildTree(practiceItems);

    function attachRolledUpTimes(node) {
      const rolled = computeRolledUpTime(node);
      let newChildren = [];
      if (node.children?.length) {
        newChildren = node.children.map((ch) => attachRolledUpTimes(ch));
      }
      return {
        ...node,
        rolledUpMs: rolled,
        children: newChildren,
      };
    }
    const withRolls = itemsWithTree.map((it) => attachRolledUpTimes(it));

    const sessionSnapshot = {
      id: generateId(),
      date: Date.now(),
      items: withRolls,
      reflection: reflectionAnswers || { q1: "", q2: "", q3: "" },
    };

    setSessionHistory((prev) => [sessionSnapshot, ...prev]);
    stopAllTimers();

    // Clear the active session
    setPracticeItems([]);
    setMode("planning");
    setSelectedItemId(null);
  }

  function ReflectionModal() {
    if (!showReflectionModal) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-xl w-full max-w-xl">
          <h2 className="text-lg font-bold mb-4 text-gray-700">
            Session Reflection
          </h2>
          <div className="space-y-4 text-sm text-gray-700">
            <label className="block">
              <span className="font-medium">
                1) Did I push myself outside my comfort zone? Specific moments?
              </span>
              <textarea
                rows={5}
                className="block w-full mt-1 p-2 border rounded"
                value={tempReflection.q1}
                onChange={(e) =>
                  setTempReflection((prev) => ({ ...prev, q1: e.target.value }))
                }
              />
            </label>
            <label className="block">
              <span className="font-medium">
                2) What did I learn about my limits or habits?
              </span>
              <textarea
                rows={5}
                className="block w-full mt-1 p-2 border rounded"
                value={tempReflection.q2}
                onChange={(e) =>
                  setTempReflection((prev) => ({ ...prev, q2: e.target.value }))
                }
              />
            </label>
            <label className="block">
              <span className="font-medium">
                3) How can I structure tomorrow's practice to build on today?
              </span>
              <textarea
                rows={5}
                className="block w-full mt-1 p-2 border rounded"
                value={tempReflection.q3}
                onChange={(e) =>
                  setTempReflection((prev) => ({ ...prev, q3: e.target.value }))
                }
              />
            </label>
          </div>
          <div className="flex items-center justify-end mt-4 space-x-2">
            <button
              onClick={() => finalizeSession(null)}
              className="px-3 py-1 text-sm bg-gray-300 hover:bg-gray-400 rounded"
            >
              Skip
            </button>
            <button
              onClick={() => finalizeSession(tempReflection)}
              className="px-3 py-1 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   RENDERING - PLANNING MODE
  // ─────────────────────────────────────────────────────────────────────

  function renderItemPlanning(item) {
    return (
      <div
        key={item.id}
        className="bg-white border border-gray-200 rounded-md shadow-sm p-4 hover:shadow-md transition-shadow w-full"
      >
        <div className="flex items-center justify-between mb-3">
          <input
            className="border border-gray-300 rounded p-2 mr-2 text-sm flex-1 focus:outline-none focus:ring-2 focus:ring-indigo-300"
            value={item.title}
            onChange={(e) => handleChangeTitle(item.id, e.target.value)}
          />
          <button
            onClick={() => handleDeleteItem(item.id)}
            className="p-1 text-red-500 hover:text-red-700 transition-colors"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>

        <label className="block text-xs text-gray-700 font-medium mt-2">
          Projected Minutes:
          <input
            type="number"
            className="border border-gray-300 rounded p-1 ml-2 w-16 text-xs focus:outline-none focus:ring-2 focus:ring-indigo-300"
            value={item.projectedMinutes}
            onChange={(e) => handleChangeProjection(item.id, e.target.value)}
          />
        </label>

        {/* Button to toggle the click-track selection UI */}
        <div className="mt-2">
          <button
            onClick={() => toggleSelectingTracks(item.id)}
            className="text-xs bg-gray-200 px-2 py-1 rounded"
          >
            Click Tracks
          </button>
          {isSelectingTracks[item.id] && (
            <div className="mt-2 border p-2 rounded bg-gray-50 text-xs">
              {/* Folder selection */}
              <label className="block font-medium mb-1">Folder:</label>
              <select
                value={selectedFolderForClickTracks}
                onChange={(e) =>
                  setSelectedFolderForClickTracks(e.target.value)
                }
                className="border p-1 rounded w-full mb-2"
              >
                <option value="">All folders</option>
                {Array.from(new Set(savedPatterns.map((sp) => sp.folder))).map(
                  (folder) => (
                    <option key={folder} value={folder}>
                      {folder}
                    </option>
                  )
                )}
              </select>

              {/* Patterns list (filtered by folder) */}
              <div className="max-h-32 overflow-y-auto border-t pt-2 mt-2">
                {savedPatterns
                  .filter((sp) =>
                    selectedFolderForClickTracks
                      ? sp.folder === selectedFolderForClickTracks
                      : true
                  )
                  .map((pattern) => {
                    const checked = isClickTrackSelected(item.id, pattern);
                    return (
                      <label key={pattern.name} className="block">
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={() =>
                            handleToggleClickTrack(item.id, pattern)
                          }
                          className="mr-1"
                        />
                        {pattern.name} ({pattern.folder})
                      </label>
                    );
                  })}
              </div>

              {/* Already selected patterns (for convenience) */}
              {item.clickTracks.length > 0 && (
                <div className="mt-2 p-2 bg-white border rounded">
                  <div className="font-medium mb-1">Attached Tracks:</div>
                  <ul className="list-disc ml-5">
                    {item.clickTracks.map((ct, idx2) => (
                      <li key={idx2} className="text-xs">
                        {ct.name} ({ct.folder})
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>

        {item.children?.length > 0 && (
          <div className="pl-4 mt-3 pt-2 border-t border-gray-100">
            {item.children.map((child) => renderItemPlanning(child))}
          </div>
        )}

        <div className="mt-3 pt-2 border-t border-gray-100">
          <button
            onClick={() => handleAddItem(item.id)}
            className="text-xs bg-[#346CBF] hover:bg-[#059DD9] text-white px-3 py-1.5 rounded transition-colors"
          >
            + Sub-Item
          </button>
        </div>
      </div>
    );
  }

  function renderPlanningView() {
    return (
      <div className="w-full">
        {/* Plan selection & labeling row */}
        <div className="flex flex-col items-center md:flex-row md:justify-center mb-6 space-y-2 md:space-y-0 md:space-x-4 bg-gray-100 p-4 rounded-md">
          <div>
            <label className="text-sm font-medium mr-2">Current Plan:</label>
            <select
              className="border border-gray-300 rounded p-1 text-sm"
              value={currentPlanId || ""}
              onChange={handleSelectPlan}
            >
              <option value="">None</option>
              {practicePlans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.label}
                </option>
              ))}
            </select>
            {currentPlanId && (
              <>
                <button
                  onClick={handleDeletePlan}
                  className="ml-2 text-xs bg-red-100 text-red-700 px-2 py-1 rounded"
                >
                  Delete Plan
                </button>
                <button
                  onClick={handleExportSinglePlan}
                  className="ml-2 text-xs bg-gray-200 text-gray-700 px-2 py-1 rounded"
                >
                  Export This Plan
                </button>
              </>
            )}
          </div>
          <div>
            <label className="text-sm font-medium mr-2">Plan Label:</label>
            <input
              className="border border-gray-300 rounded p-1 text-sm"
              value={planLabelInput}
              onChange={(e) => setPlanLabelInput(e.target.value)}
              placeholder="e.g. Daily Warm-Up"
            />
            <button
              onClick={handleSavePlan}
              className="ml-2 text-xs bg-green-100 text-green-700 px-2 py-1 rounded"
            >
              Save Plan
            </button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-3 space-y-3 md:space-y-0 mb-6">
          <button
            onClick={() => handleAddItem(null)}
            className="px-4 py-2 bg-[#059DD9] hover:bg-[#346CBF] text-white rounded-md shadow-sm transition-colors font-medium"
          >
            + Practice Block
          </button>
          <button
            onClick={handleStartSession}
            className="px-4 py-2 bg-[#B71E4B] hover:bg-[#CB245B] text-white rounded-md shadow-sm transition-colors font-medium"
          >
            Go to Active
          </button>
        </div>

        {/* Grid layout for planning items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {treeData.length === 0 && (
            <div className="col-span-full text-center p-8 text-gray-500">
              <div className="text-5xl mb-4">🎯</div>
              <p className="text-xl font-medium mb-2">No practice items yet</p>
              <p className="text-sm">
                Click "+ Practice Block" to create your first practice item
              </p>
            </div>
          )}
          {treeData.map((topItem) => renderItemPlanning(topItem))}
        </div>
      </div>
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   ACTIVE MODE
  // ─────────────────────────────────────────────────────────────────────

  function handleSplitLabelChange(itemId, index, newLabel) {
    setPracticeItems((prev) =>
      prev.map((it) => {
        if (it.id !== itemId) return it;
        const newSplits = [...it.splits];
        newSplits[index] = { ...newSplits[index], label: newLabel };
        return { ...it, splits: newSplits };
      })
    );
  }

  function renderSelectedItemDetail() {
    const item = practiceItems.find((it) => it.id === selectedItemId);
    if (!item) return <div>Item not found</div>;
    const colorClass = getTimeColor(item);

    return (
      <div className="flex flex-col md:flex-row items-start w-full space-y-4 md:space-y-0 md:space-x-6">
        {/* Left column: main info */}
        <div className="flex-1">
          <button
            onClick={() => setSelectedItemId(null)}
            className="mb-4 text-sm bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-full flex items-center font-medium transition-colors"
          >
            <span className="mr-1">←</span> Back to Overview
          </button>

          <div className="border border-gray-200 rounded-lg shadow-md p-6 bg-white">
            <h2 className="font-bold text-xl mb-3 text-gray-800">
              {item.title}
            </h2>
            <div className="flex items-center mb-4 p-3 bg-gray-50 rounded-lg">
              <div className="flex-1">
                <div className="text-xs uppercase font-semibold text-gray-500">
                  Current Time
                </div>
                <div className={`text-xl font-bold ${colorClass}`}>
                  {formatTime(item.actualElapsedMs)}
                </div>
              </div>
              <div className="text-2xl text-gray-300 mx-2">/</div>
              <div className="flex-1">
                <div className="text-xs uppercase font-semibold text-gray-500">
                  Projection
                </div>
                <div className="text-xl font-bold text-gray-700">
                  {item.projectedMinutes}m
                </div>
              </div>
            </div>

            <div className="flex space-x-3 mb-5">
              {item.isRunning ? (
                <button
                  onClick={() => stopItemTimer(item.id)}
                  className="flex-1 px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors font-medium"
                >
                  Stop
                </button>
              ) : (
                <button
                  onClick={() => startItemTimer(item.id)}
                  className="flex-1 px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md transition-colors font-medium"
                >
                  Start
                </button>
              )}
              <button
                onClick={() => handleSplit(item.id)}
                className={`flex-1 px-4 py-2 rounded-md font-medium transition-colors ${
                  item.isRunning
                    ? "bg-blue-500 hover:bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-400 cursor-not-allowed"
                }`}
                disabled={!item.isRunning}
              >
                Split
              </button>
            </div>

            {item.splits && item.splits.length > 0 && (
              <div className="mt-5 p-4 bg-gray-50 rounded-lg">
                <h4 className="font-semibold text-gray-700 mb-2">Splits:</h4>
                <div className="max-h-40 overflow-y-auto pr-2">
                  {item.splits.map((splitObj, idx2) => (
                    <div
                      key={idx2}
                      className="flex items-center py-1.5 border-b border-gray-100 last:border-0 text-sm"
                    >
                      <div className="mr-2 text-xs font-semibold bg-indigo-100 text-indigo-800 rounded-full w-5 h-5 flex items-center justify-center">
                        {idx2 + 1}
                      </div>
                      <div className="flex-1">
                        <div>{formatTime(splitObj.time)}</div>
                        <input
                          className="border border-gray-300 rounded p-1 text-xs mt-1 w-full"
                          placeholder="Split label"
                          value={splitObj.label}
                          onChange={(e) =>
                            handleSplitLabelChange(
                              item.id,
                              idx2,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* The user’s chosen click tracks in active mode (readonly or modifiable) */}
          <div className="mt-4 p-4 border rounded bg-white">
            <h4 className="font-semibold text-sm mb-2">
              Click Tracks for "{item.title}"
            </h4>
            {item.clickTracks.length === 0 && (
              <p className="text-xs text-gray-500">No tracks selected.</p>
            )}
            {item.clickTracks.map((ct, idx2) => (
              <div
                key={idx2}
                className="text-xs flex items-center justify-between mb-1"
              >
                <span>
                  {ct.name} ({ct.folder})
                </span>
                <button
                  onClick={() => handleToggleClickTrack(item.id, ct)}
                  className="text-red-500 hover:text-red-700 text-xs"
                >
                  Remove
                </button>
              </div>
            ))}
            {/* If you want to allow adding new tracks in Active mode, 
                you can re-use the same UI approach as planning:
            */}
            <button
              onClick={() => toggleSelectingTracks(item.id)}
              className="text-xs bg-gray-200 px-2 py-1 rounded mt-2"
            >
              Add/Remove Tracks
            </button>
            {isSelectingTracks[item.id] && (
              <div className="mt-2 border p-2 rounded bg-gray-50 text-xs">
                <label className="block font-medium mb-1">Folder:</label>
                <select
                  value={selectedFolderForClickTracks}
                  onChange={(e) =>
                    setSelectedFolderForClickTracks(e.target.value)
                  }
                  className="border p-1 rounded w-full mb-2"
                >
                  <option value="">All folders</option>
                  {Array.from(
                    new Set(savedPatterns.map((sp) => sp.folder))
                  ).map((folder) => (
                    <option key={folder} value={folder}>
                      {folder}
                    </option>
                  ))}
                </select>
                <div className="max-h-32 overflow-y-auto border-t pt-2 mt-2">
                  {savedPatterns
                    .filter((sp) =>
                      selectedFolderForClickTracks
                        ? sp.folder === selectedFolderForClickTracks
                        : true
                    )
                    .map((pattern) => {
                      const checked = isClickTrackSelected(item.id, pattern);
                      return (
                        <label key={pattern.name} className="block">
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={() =>
                              handleToggleClickTrack(item.id, pattern)
                            }
                            className="mr-1"
                          />
                          {pattern.name} ({pattern.folder})
                        </label>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right column: free-form comments */}
        <div className="w-full md:w-1/3">
          <label className="block mb-1 text-sm font-semibold text-gray-700">
            Comments / Notes
          </label>
          <textarea
            className="w-full h-64 border border-gray-300 rounded p-2 text-sm"
            placeholder="Type your thoughts or additional info here..."
            value={item.comments}
            onChange={(e) => handleChangeComments(item.id, e.target.value)}
          />
        </div>
      </div>
    );
  }

  function renderAddSubItemButton(itemId) {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleAddItem(itemId);
        }}
        className="ml-2 text-xs bg-[#346CBF] text-white px-2 py-1 rounded hover:bg-[#059DD9]"
      >
        + Sub-Item
      </button>
    );
  }

  function renderItemActive(item) {
    const colorClass = getTimeColor(item);
    return (
      <div
        key={item.id}
        className="bg-white border border-gray-200 rounded-md shadow-sm p-4 w-full hover:shadow-md transition-shadow"
      >
        <div className="flex items-center justify-between">
          {/* Title (inline editable) */}
          <input
            className="border-b border-transparent focus:border-indigo-300 focus:outline-none text-sm font-semibold flex-1 mr-2"
            value={item.title}
            onChange={(e) => handleChangeTitle(item.id, e.target.value)}
          />
          {/* Expand/Collapse children */}
          {item.children?.length > 0 && (
            <button
              className="text-xs bg-gray-100 hover:bg-gray-200 border border-gray-300 px-2 py-1 rounded transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand(item.id);
              }}
            >
              {expandedItems[item.id] ? "▼" : "▶"}
            </button>
          )}
        </div>
        <div
          onClick={() => handleItemClick(item)}
          className="cursor-pointer mt-2"
        >
          <div className={`text-xs font-medium ${colorClass}`}>
            {formatTime(item.actualElapsedMs)} / {item.projectedMinutes}m
          </div>
        </div>
        <div className="mt-2">
          {renderAddSubItemButton(item.id)}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteItem(item.id);
            }}
            className="ml-2 text-xs text-red-500 hover:text-red-700"
          >
            Delete
          </button>
        </div>

        {item.children?.length > 0 && expandedItems[item.id] && (
          <div className="pl-4 mt-3 pt-2 border-t border-gray-100">
            {item.children.map((child) => renderItemActive(child))}
          </div>
        )}

        {/* Show if user wants to see or add click tracks in “overview” mode */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            toggleSelectingTracks(item.id);
          }}
          className="mt-2 text-xs bg-gray-200 px-2 py-1 rounded"
        >
          Click Tracks
        </button>
        {isSelectingTracks[item.id] && (
          <div className="mt-2 border p-2 rounded bg-gray-50 text-xs">
            <label className="block font-medium mb-1">Folder:</label>
            <select
              value={selectedFolderForClickTracks}
              onChange={(e) => setSelectedFolderForClickTracks(e.target.value)}
              className="border p-1 rounded w-full mb-2"
            >
              <option value="">All folders</option>
              {Array.from(new Set(savedPatterns.map((sp) => sp.folder))).map(
                (folder) => (
                  <option key={folder} value={folder}>
                    {folder}
                  </option>
                )
              )}
            </select>
            <div className="max-h-32 overflow-y-auto border-t pt-2 mt-2">
              {savedPatterns
                .filter((sp) =>
                  selectedFolderForClickTracks
                    ? sp.folder === selectedFolderForClickTracks
                    : true
                )
                .map((pattern) => {
                  const checked = isClickTrackSelected(item.id, pattern);
                  return (
                    <label key={pattern.name} className="block">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() =>
                          handleToggleClickTrack(item.id, pattern)
                        }
                        className="mr-1"
                      />
                      {pattern.name} ({pattern.folder})
                    </label>
                  );
                })}
            </div>
            {item.clickTracks.length > 0 && (
              <div className="mt-2 p-2 bg-white border rounded">
                <div className="font-medium mb-1">Attached Tracks:</div>
                <ul className="list-disc ml-5">
                  {item.clickTracks.map((ct, idx2) => (
                    <li key={idx2} className="text-xs">
                      {ct.name} ({ct.folder})
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderActiveView() {
    if (selectedItemId) {
      return renderSelectedItemDetail();
    }
    return (
      <div className="w-full">
        <div className="flex justify-center mb-6 space-x-4">
          <button
            onClick={() => handleAddItem(null)}
            className="px-4 py-2 bg-[#059DD9] hover:bg-[#346CBF] text-white rounded-md shadow-sm transition-colors font-medium"
          >
            + Practice Block
          </button>
          <button
            onClick={handleStopSessionConfirmation}
            className="px-4 py-2 bg-[#B71E4B] hover:bg-[#CB245B] text-white rounded-md shadow-sm transition-colors font-medium"
          >
            End Session
          </button>
          <button
            onClick={() => setMode("planning")}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md shadow-sm transition-colors font-medium"
          >
            Edit Plan
          </button>
        </div>

        {/* Grid layout for active items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border border-gray-200 rounded-lg p-4">
          {treeData.map((topItem) => renderItemActive(topItem))}
          {treeData.length === 0 && (
            <div className="col-span-full text-center p-4 text-gray-500">
              <p className="text-sm">
                No items yet. Click "+ Practice Block" to create one.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   REVIEW MODE
  // ─────────────────────────────────────────────────────────────────────

  function handleDeleteSession(sessionId) {
    const confirmDel = window.confirm(
      "Are you sure you want to delete this past session?"
    );
    if (!confirmDel) return;
    setSessionHistory((prev) => prev.filter((s) => s.id !== sessionId));
  }

  function renderReviewView() {
    return (
      <div className="max-w-4xl mx-auto w-full">
        <button
          onClick={() => setMode("planning")}
          className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md shadow-sm transition-colors font-medium mb-6"
        >
          Back to Plan
        </button>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold text-gray-800 border-b border-gray-200 pb-2">
            Session History
          </h2>
          <div className="flex space-x-2 items-center">
            <input
              id="importSessions"
              type="file"
              accept=".json,.txt"
              style={{ display: "none" }}
              onChange={handleImportSessions}
            />
            <label
              htmlFor="importSessions"
              className="px-3 py-1 text-sm bg-gray-300 hover:bg-gray-400 rounded cursor-pointer"
            >
              Import
            </label>
            <button
              onClick={handleExportSessions}
              className="px-3 py-1 text-sm bg-gray-300 hover:bg-gray-400 rounded"
            >
              Export
            </button>
          </div>
        </div>

        {sessionHistory.length === 0 && (
          <div className="text-center p-12 bg-gray-50 rounded-lg text-gray-500">
            <div className="text-5xl mb-4">📅</div>
            <p className="text-xl font-medium">No saved sessions yet</p>
            <p className="text-sm mt-2">
              Complete a practice session to see your history
            </p>
          </div>
        )}
        <div className="grid gap-4 md:grid-cols-2">
          {sessionHistory.map((session) => (
            <SessionCard key={session.id} session={session} />
          ))}
        </div>
      </div>
    );
  }

  function SessionCard({ session }) {
    const dateStr = new Date(session.date).toLocaleDateString(undefined, {
      weekday: "long",
      month: "short",
      day: "numeric",
    });
    const timeStr = new Date(session.date).toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });

    function sumTopLevelRolled(tree) {
      let total = 0;
      for (const node of tree) {
        total += node.rolledUpMs || 0;
      }
      return total;
    }
    const totalMs = sumTopLevelRolled(session.items || []);
    const totalMin = Math.round(totalMs / 60000);

    const { q1, q2, q3 } = session.reflection || {};
    const [showDetails, setShowDetails] = useState(false);

    return (
      <div className="border border-gray-200 rounded-lg shadow-sm p-4 bg-white hover:shadow-md transition-all relative">
        <button
          onClick={() => handleDeleteSession(session.id)}
          className="absolute top-2 right-2 text-red-500 hover:text-red-700"
        >
          <Trash2 className="w-4 h-4" />
        </button>
        <div className="flex items-center justify-between">
          <div>
            <div className="font-semibold text-indigo-700 mb-1">{dateStr}</div>
            <div className="text-sm text-gray-500">{timeStr}</div>
          </div>
          <div className="text-xs text-gray-500">{totalMin} min total</div>
        </div>

        <div className="mt-3 pt-3 border-t border-gray-100 space-y-2">
          {(q1 || q2 || q3) && (
            <div className="p-2 bg-gray-50 rounded text-sm text-gray-800">
              {q1 && (
                <div className="mb-2">
                  <div className="font-semibold">
                    Q1: Did I push myself outside my comfort zone?
                  </div>
                  <div className="whitespace-pre-line">{q1}</div>
                </div>
              )}
              {q2 && (
                <div className="mb-2">
                  <div className="font-semibold">
                    Q2: What did I learn about my limits?
                  </div>
                  <div className="whitespace-pre-line">{q2}</div>
                </div>
              )}
              {q3 && (
                <div>
                  <div className="font-semibold">
                    Q3: How can I structure tomorrow’s practice?
                  </div>
                  <div className="whitespace-pre-line">{q3}</div>
                </div>
              )}
            </div>
          )}
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="text-xs px-3 py-1 bg-gray-200 rounded hover:bg-gray-300"
          >
            {showDetails ? "Hide Details" : "View Details"}
          </button>
          {showDetails && (
            <div className="mt-2 border-t border-gray-100 pt-2">
              {session.items.map((it) => (
                <SessionItemTree key={it.id} item={it} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  function SessionItemTree({ item, level = 0 }) {
    const pad = { paddingLeft: level * 16 + "px" };
    return (
      <div style={pad} className="py-1 text-sm text-gray-700">
        <div className="font-medium">
          • {item.title} ({formatTime(item.rolledUpMs || 0)})
        </div>
        {/* Display user-entered notes if any */}
        {item.comments && (
          <div className="pl-4 text-xs text-gray-500 italic">
            Notes: {item.comments}
          </div>
        )}
        {item.children?.length > 0 &&
          item.children.map((child) => (
            <SessionItemTree key={child.id} item={child} level={level + 1} />
          ))}
      </div>
    );
  }

  function renderPlanImportExport() {
    return (
      <div className="flex items-center justify-center mt-8 space-x-3">
        <div>
          <input
            id="importPlans"
            type="file"
            accept=".json,.txt"
            style={{ display: "none" }}
            onChange={handleImportPlans}
          />
          <label
            htmlFor="importPlans"
            className="px-3 py-1 text-sm bg-gray-300 hover:bg-gray-400 rounded cursor-pointer"
          >
            Import Plans
          </label>
        </div>
        <button
          onClick={handleExportPlans}
          className="px-3 py-1 text-sm bg-gray-300 hover:bg-gray-400 rounded"
        >
          Export All Plans
        </button>
      </div>
    );
  }

  function renderTabNavigation() {
    return (
      <div className="flex justify-center mb-6">
        <div className="inline-flex rounded-md shadow-sm">
          <button
            onClick={() => setMode("planning")}
            className={`px-4 py-2 text-sm font-medium rounded-l-md border ${
              mode === "planning"
                ? "bg-[#346CBF] text-white border-indigo-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            Planning
          </button>
          <button
            onClick={() => setMode("active")}
            className={`px-4 py-2 text-sm font-medium border-t border-b ${
              mode === "active"
                ? "bg-[#346CBF] text-white border-indigo-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            Active
          </button>
          <button
            onClick={() => setMode("review")}
            className={`px-4 py-2 text-sm font-medium rounded-r-md border ${
              mode === "review"
                ? "bg-[#346CBF] text-white border-indigo-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            } ${!sessionHistory.length && "opacity-50 cursor-not-allowed"}`}
            disabled={!sessionHistory.length}
          >
            Review
          </button>
        </div>
      </div>
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  //   MAIN
  // ─────────────────────────────────────────────────────────────────────

  return (
    <div className="p-1 mx-auto bg-gray-250 min-h-screen w-full relative">
      <ReflectionModal />
      <div className="text-center mb-3">
        <img
          src={flowFrameLogo}
          alt="FlowFrame Logo"
          className="inline-block w-48"
        />
      </div>
      <div className="text-center mb-8">
        <h1 className="font-semibold text-lg text-gray-800">
          Empowering Confident Artistry
        </h1>
        <p className="text-gray-600 text-sm">
          Track, time, and optimize your practice sessions
        </p>
      </div>

      {renderTabNavigation()}

      <div className="bg-white rounded-lg shadow-sm p-6 mb-6 w-full">
        {mode === "planning" && (
          <>
            {renderPlanningView()}
            {renderPlanImportExport()}
          </>
        )}
        {mode === "active" && renderActiveView()}
        {mode === "review" && renderReviewView()}
      </div>
    </div>
  );
}
